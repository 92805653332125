const $ = jQuery;

/* eslint-disable-next-line import/no-default-export */
export default () => {
  $(() => {
    const modal = $('#modal');
    const product = $('.licensed-products .product');
    const modalContent = $('#modal #modal-content');

    function init() {
      product.click((element) => {
        $('#modal-content .product-wrapper .image').html(
          $(element.currentTarget).find('img:first').clone()
        );
        $('#modal-content .product-wrapper .title').html(
          $(element.currentTarget).find('.product__title:first').clone()
        );
        $('#modal-content .product-wrapper .price').html(
          $(element.currentTarget).find('.product__price:first').clone()
        );
        $('#modal-content .product-wrapper .description').html(
          $(element.currentTarget).find('.product__full-info--desc:first').clone()
        );
        modal.show();
      });
    }

    product.off('click mouseenter mouseleave');
    init();

    $(window).click((e) => {
      if (e.target.id === 'modal') {
        modal.hide();
      }
    });

    modalContent.click(() => {
      modal.hide();
    });
  });
};
