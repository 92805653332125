// Import scripts and run them
import general from './modules/general';
import slick from './modules/slickslider';
import mobile from './modules/mobile';
import ajax from './modules/ajax';
import search from './modules/search';
import copyrightStatement from './modules/copyrightStatement';
import ads from './modules/ads';
import productModal from './modules/productModal';

import '../scss/style.scss';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    general();
    slick();
    mobile();
    ajax();
    search();
    copyrightStatement();
    ads();
    productModal();
  },
  false
);

// this module needs to accept HMR updates for itself
// @see https://webpack.js.org/api/hot-module-replacement/#accept-self
if (module.hot) {
  module.hot.accept((err) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.error('[HMR] App - Cannot apply HMR update.', err);
    }
    // eslint-disable-next-line no-console
    console.log('[HMR] App - update applied');
  });
}
