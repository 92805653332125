// Function to improve ads
/* eslint-disable-next-line import/no-default-export */
export default () => {
  window.addEventListener('load', () => {
    // selectors
    const skyscraper = document.querySelector('.ads_v1');
    const topScraper = document.querySelector('.ads_h1');
    const midWrapper = document.querySelector('.s-body > .s-column__wrapper');
    // get width
    let windowWidth = window.innerWidth;
    let skyscraperWidth = skyscraper.clientWidth;
    let midWrapperWidth = midWrapper.offsetWidth;
    // get distance from corners
    let midWrapperLeftOffset = midWrapper.getBoundingClientRect().left;
    let skyscraperFromTop = skyscraper.getBoundingClientRect().top;
    // position right
    let rightPositionSkyscraper = midWrapperLeftOffset + midWrapperWidth;
    skyscraper.style.right = `${rightPositionSkyscraper.toString()}px`;

    if (window.pageYOffset > skyscraperFromTop + topScraper.clientHeight) {
      skyscraper.style.position = 'fixed';
    } else {
      skyscraper.style.position = 'absolute';
    }

    if (skyscraperWidth + midWrapperWidth + midWrapperLeftOffset < windowWidth) {
      skyscraper.style.opacity = '1';
    }
    // set the right position for the skyscrapper
    // based on wrapper plus the size of skyscraper
    window.addEventListener('resize', () => {
      windowWidth = window.innerWidth;
      skyscraperWidth = skyscraper.offsetWidth;
      midWrapperWidth = midWrapper.offsetWidth;
      midWrapperLeftOffset = midWrapper.getBoundingClientRect().left;
      // position right
      rightPositionSkyscraper = midWrapperLeftOffset + midWrapperWidth;
      skyscraper.style.right = `${rightPositionSkyscraper.toString()}px`;
    });

    // on scroll we will check when we can switch from absolute to fixed or other way around
    window.addEventListener('scroll', () => {
      skyscraperFromTop = skyscraper.getBoundingClientRect().top;

      if (window.pageYOffset > skyscraperFromTop + topScraper.clientHeight) {
        skyscraper.style.position = 'fixed';
      } else {
        skyscraper.style.position = 'absolute';
      }
      if (window.pageYOffset > skyscraperFromTop + topScraper.clientHeight) {
        skyscraper.style.top = '40px';
      } else {
        skyscraper.style.top = '';
      }
    });
  });
};
