const $ = jQuery;
/* eslint-disable-next-line import/no-default-export */
export default () => {
  const header = $('header[data-copyright-statement="1"]');

  if (header.length > 0) {
    const statement = `
    <div class='fader'>
    </div>
      <div class='statement'>
        <div class='statement__content'>
          <h2 class='statement__content--warning'>
            Let op!
          </h2>
          <div>
            <i class='statement__close fas fa-times'></i>
          </div>
          <p class='statement__content--text'>
            Deze foto is van vtwonen en mag je niet zomaar kopiëren en opslaan.
            Zie ook onze <a href="https://simservice.dpgmm.nl/optiext/optiextension.dll?ID=Y78YQhFBwOQiwma5dxkxQ_0G9wF8ctiwG1aDba_gG_eKHS_ixKO91tDy7RBj4smrD1cnESqFVsVYkI&type=cr&sn=vtwonen&tld=nl&pub=1" class="statement__content--link">copyrights</a>.
          </p>
          <p>
            Hi! You want to copy and save this
            photo? Please be aware of it's
            <a href="https://simservice.dpgmm.nl/optiext/optiextension.dll?ID=Y78YQhFBwOQiwma5dxkxQ_0G9wF8ctiwG1aDba_gG_eKHS_ixKO91tDy7RBj4smrD1cnESqFVsVYkI&type=cr&sn=vtwonen&tld=nl&pub=1" class="statement__content--link">copyrights</a>.
          </p>
        </div>
      </div>
    `;

    $(document).on('mousedown', $('img'), (event) => {
      if (event.button === 2 && event.target.tagName.toLowerCase() === 'img') {
        if (header.find('.statement').length === 0) {
          header.append(statement);
          const statementElement = header.find('.statement');
          const closeButton = statementElement.find('.statement__close');
          closeButton.on('click', () => {
            statementElement.remove();
            header.find('.fader').remove();
          });
        }
      }
    });
  }
};
