import 'slick-carousel-latest';
// Function to init slick sliders
const $ = jQuery;
/* eslint-disable-next-line import/no-default-export */
export default () => {
  $('.s-content ul.wp-block-gallery').each((index, element) => {
    $(element).slick({
      autoplay: true,
      autoplaySpeed: 6000,
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: '<i class="fa fa-chevron-right"></i>',
      prevArrow: '<i class="fa fa-chevron-left"></i>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: 'unslick'
        // instead of a settings object
      ],
    });
  });

  if ($(window).width() < 900) {
    if (
      $('.s-content .image-products-block .image-products-block__products').children().length !== 2
    ) {
      $('.s-content .image-products-block .image-products-block__products').each(
        (index, element) => {
          $(element).slick({
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '60px',
            nextArrow: '<i class="fa fa-chevron-right"></i>',
            prevArrow: '<i class="fa fa-chevron-left"></i>',
          });
        }
      );
    }
  }

  $('.s-content .category-posts-block .category-results-holder').each((index, element) => {
    $(element).slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      mobileFirst: true,
      nextArrow: '<i class="fa fa-chevron-right"></i>',
      prevArrow: '<i class="fa fa-chevron-left"></i>',
      responsive: [
        {
          breakpoint: 900,
          settings: 'unslick',
        },
      ],
    });
  });

  $('.s-content .block-products .block-products__slider').each((index, element) => {
    $(element).slick({
      slidesToShow: 3,
      nextArrow: '<i class="fa fa-chevron-right"></i>',
      prevArrow: '<i class="fa fa-chevron-left"></i>',
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  });

  $('.s-content .related-products .related-products__slider').each((index, element) => {
    $(element).slick({
      slidesToShow: 3,
      nextArrow: '<i class="fa fa-chevron-right"></i>',
      prevArrow: '<i class="fa fa-chevron-left"></i>',
      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  });

  $('.s-content .js-related-posts__slider').each((index, element) => {
    $(element).slick({
      slidesToShow: 3,
      nextArrow: '<i class="fa fa-chevron-right"></i>',
      prevArrow: '<i class="fa fa-chevron-left"></i>',
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  });

  $('.s-content .slideshow').each((index, element) => {
    $(element).slick({
      autoplay: true,
      autoplaySpeed: 8000,
      dots: false,
      infinite: true,
      padding: 100,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: '<i class="fa fa-chevron-right"></i>',
      prevArrow: '<i class="fa fa-chevron-left"></i>',
    });
  });

  $('.s-content .collection-slider').each((index, element) => {
    $(element).slick({
      autoplay: true,
      autoplaySpeed: 8000,
      dots: false,
      infinite: true,
      padding: 100,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: '<i class="fa fa-chevron-right"></i>',
      prevArrow: '<i class="fa fa-chevron-left"></i>',
    });
  });

  $('.promotion-content-block .licensed-products').each((index, element) => {
    $(element).slick({
      autoplay: true,
      autoplaySpeed: 8000,
      dots: false,
      infinite: true,
      padding: 100,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: '<i class="fa fa-chevron-right"></i>',
      prevArrow: '<i class="fa fa-chevron-left"></i>',
      responsive: [
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  });
};
