// Function to init sticky Sidebar
const $ = jQuery;

// Importing constants to Javascript from CSS
// See: https://blog.bluematador.com/posts/how-to-share-variables-between-js-and-sass/
/* eslint-disable-next-line import/no-default-export */
export default () => {
  $('.mobile-button').click(() => {
    $('html').toggleClass('mobile-active');
    $('.nav-mobile-holder').toggleClass('active');
    return false;
  });

  $('.close-mobile').click(() => {
    $('html').removeClass('mobile-active');
    $('.nav-mobile-holder').removeClass('active');
  });

  $('.nav-drop .back-btn').click((e) => {
    $(e.currentTarget).parent().removeClass('active');
  });

  $('footer .s-column__wrapper div h2').click((e) => {
    $(e.currentTarget).next().find('.menu').slideToggle();
    $(e.currentTarget).toggleClass('active');
  });

  $('.wp-block-image, .image-products-block__image').click((e) => {
    $(e.currentTarget).toggleClass('active');
  });

  $('figcaption')
    .parent('.wp-block-image')
    .append('<div class="close-btn"><i class="fas fa-times"></i></div>');

  $('figcaption')
    .parent('.image-products-block__image')
    .append('<div class="close-btn"><i class="fas fa-times"></i></div>');
};
