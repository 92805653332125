const $ = jQuery;
/* eslint-disable-next-line import/no-default-export */
export default () => {
  const $searchInput = $('.header-nav-top-search-input');

  function setUrls() {
    const searchVal = encodeURIComponent($searchInput.val());
    const shopUrl = `https://shop.vtwonen.nl/zoek/?fq=${searchVal}`;
    const siteUrl = `?s=${searchVal}`;

    $('.search-shop').attr('href', shopUrl);
    $('.search-site').attr('href', siteUrl);
  }

  setUrls();

  if (encodeURIComponent($($searchInput).val()) === '') {
    $('.search-button').attr('disabled', true);
  }
  $searchInput.focus(() => {
    const searchVal = encodeURIComponent($searchInput.val());

    if (searchVal.trim().length !== 0) {
      $('.search-dropdown').removeClass('hidden');
      setUrls();
    }
  });

  $searchInput.keyup(() => {
    const searchVal = encodeURIComponent($searchInput.val());

    if (searchVal.trim().length === 0) {
      $('.search-dropdown').addClass('hidden');
    } else {
      $('.search-dropdown').removeClass('hidden');
      $('.search-button').attr('disabled', false);
    }

    $('.search-site .search-query, .search-shop .search-query').html(searchVal);

    setUrls();
  });
};
