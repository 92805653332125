// Function to init sticky Sidebar
const $ = jQuery;

// Importing constants to Javascript from CSS
// See: https://blog.bluematador.com/posts/how-to-share-variables-between-js-and-sass/

/* eslint-disable-next-line import/no-default-export */
export default () => {
  const btn = $('#scrolltotop');

  $('.js-lookbook-gridnav-gallery').click((e) => {
    $(e.currentTarget).addClass('active');
    $('.js-lookbook-gridnav-list').removeClass('active');
    $('.js-lookbook__container').removeClass('lookbook__container-list');
    return false;
  });

  $('.js-lookbook-gridnav-list').click((e) => {
    $(e.currentTarget).addClass('active');
    $('.js-lookbook-gridnav-gallery').removeClass('active');
    $('.js-lookbook__container').addClass('lookbook__container-list');
    return false;
  });

  $('.js-stop-immediate-propagation').click((e) => {
    e.stopImmediatePropagation();
    return true;
  });

  $(window).scroll(() => {
    if ($(window).scrollTop() > 300) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', (e) => {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '300');
  });

  const product = $('.licensed-products .product');
  const closeProduct = $(
    '.product__full-info--close , .product__full-info--desc, .product__information'
  );

  product.hover((e) => {
    if (e.type === 'mouseenter') {
      $(e.currentTarget)
        .find('.product__full-info.hidden')
        .animate(
          {
            height: '50%',
          },
          {
            duration: 200,
            specialEasing: {
              height: 'linear',
            },
          }
        );
    }

    if (e.type === 'mouseleave') {
      $(e.currentTarget)
        .find('.product__full-info.hidden')
        .animate(
          {
            height: '0',
          },
          {
            duration: 200,
            specialEasing: {
              height: 'linear',
            },
            complete() {
              $(this).find('.product__full-info--close i').hide();
              $(this).find('.product__full-info--close span').hide();
              $(this).removeClass('shown').addClass('hidden');
            },
          }
        );
    }
  });

  product.click((e) => {
    $(e.currentTarget).find('.product__full-info--close i').show();
    $(e.currentTarget).find('.product__full-info--close span').show();

    $(e.currentTarget)
      .find('.product__full-info.hidden')
      .animate(
        {
          height: '100%',
        },
        {
          duration: 200,
          specialEasing: {
            height: 'linear',
          },
          complete() {
            $(this).removeClass('hidden').addClass('shown');
          },
        }
      );
  });
  closeProduct.on('click', (e) => {
    $(e.currentTarget)
      .parent('.shown')
      .animate(
        {
          height: '0',
        },
        {
          duration: 200,
          specialEasing: {
            height: 'linear',
          },
          complete() {
            $(this).find('.product__full-info--close i').hide();
            $(this).find('.product__full-info--close span').hide();
            $(this).removeClass('shown').addClass('hidden');
          },
        }
      );
  });
};
