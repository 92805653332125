// Function to init sticky Sidebar
const $ = jQuery;

// Importing constants to Javascript from CSS
// See: https://blog.bluematador.com/posts/how-to-share-letiables-between-js-and-sass/
/* eslint-disable-next-line import/no-default-export */
export default () => {
  let fetchingPosts = false;
  $('.js-loadmore-trigger').click((e) => {
    const self = $(e.currentTarget);
    const offset = self.attr('data-offset');
    const category = self.attr('data-category-id');
    const postType = self.attr('data-post_type');
    const collection = self.attr('data-collection-id');
    const tag = self.attr('data-tag-id');
    const action = self.attr('data-action');
    const label = self.attr('data-label');
    const search = self.attr('data-search');
    const labelLoading = self.attr('data-label-loading');

    if (fetchingPosts) {
      return;
    }

    fetchingPosts = true;
    self.addClass('loading');
    self.text(labelLoading);

    const data = {
      action,
      offset,
      search,
    };

    if (category !== undefined) {
      data.category = category;
    }

    if (postType !== undefined) {
      data.post_type = postType;
    }

    if (tag !== undefined) {
      data.tag = tag;
    }

    if (collection !== undefined) {
      data.collection = collection;
    }

    jQuery.get(admin.ajaxUrl, data, (response) => {
      $('.js-loadmore-container').append(response.data.html);
      self.attr('data-offset', response.data.offset);
      fetchingPosts = false;
      self.removeClass('loading');
      self.text(label);

      if (response.data.hideButton === true) {
        self.remove();
      }
    });
  });
};
